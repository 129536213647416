import $ from "jquery";
import { truncate } from "lodash";
import { isInternalLink } from "~/src/utils/isExternalLink";
import Analytics from "~/src/utils/Analytics";

function initExternalLinks() {
  $("a").on("click", function (e) {
    const href = this.href;
    if (isInternalLink(href)) {
      return;
    }

    Analytics.event("click", "external link", truncate(href, { length: 125 }));
  });
}

export default initExternalLinks;
